<template>
  <v-container id="about-us" fluid>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card class="mt-0" shaped>
          <v-card-title class="success">
            <v-btn class="mx-1" text to="/">
              <v-icon> mdi-chevron-left </v-icon>
            </v-btn>
            <v-spacer></v-spacer> 
            <div class="display-2 white--text" color="white">ABOUT US</div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-subtitle> </v-card-subtitle>
          <v-card-text>
            <br/>
                 <p>
                   This Information System is taken from the consolidated information from the MAIS FARMS which was developed by the Municipality of Mina. MAIS FARMS is Registered with <b>IPO under REGISTRATION No. N2015-00</b>
                 
                  </p>

                </v-card-text>
              </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
 
};
</script>
